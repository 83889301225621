import { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconTwitter: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >

    {/* eslint-disable max-len */}
    <path
      d="M15.4706 2.35624C14.909 2.6035 14.3141 2.76671 13.705 2.84062C14.3463 2.45515 14.8265 1.85065 15.0569 1.13874C14.4625 1.48562 13.8038 1.73812 13.1025 1.87874C12.6398 1.38401 12.0268 1.05588 11.3585 0.945307C10.6903 0.834732 10.0042 0.9479 9.40684 1.26724C8.80948 1.58658 8.33429 2.09421 8.05503 2.71132C7.77578 3.32843 7.7081 4.02047 7.8625 4.67999C5.30625 4.55937 3.04188 3.33124 1.525 1.47624C1.24925 1.94488 1.10541 2.47938 1.10875 3.02312C1.10875 4.09187 1.6525 5.03124 2.47625 5.58312C1.98804 5.56758 1.51061 5.43558 1.08375 5.19812V5.23562C1.08347 5.94586 1.32892 6.63433 1.77844 7.18422C2.22797 7.7341 2.85388 8.11154 3.55 8.25249C3.09894 8.37336 2.62651 8.39151 2.1675 8.30562C2.36508 8.91687 2.74854 9.45121 3.26438 9.83405C3.78023 10.2169 4.40272 10.4292 5.045 10.4412C3.9572 11.295 2.61405 11.7583 1.23125 11.7569C0.9875 11.7569 0.744375 11.7425 0.5 11.715C1.90982 12.6177 3.54907 13.0969 5.22312 13.0956C10.8813 13.0956 13.9719 8.41062 13.9719 4.35499C13.9719 4.22374 13.9719 4.09249 13.9625 3.96124C14.5662 3.52678 15.087 2.98738 15.5 2.36874L15.4706 2.35624Z"
      fill="currentcolor"
    />
  </svg>
);

export default IconTwitter;
