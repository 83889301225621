import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconCloseCircleOutline: FC<SvgIconProps> = ({
  className = '',
}): ReactElement => (
  <svg fill="none" viewBox="0 0 68 68" className={className}>
    {/* eslint-disable max-len */}
    <path d="M21.5 50.0003L34 37.5003L46.5 50.0003L50 46.5003L37.5 34.0003L50 21.5003L46.5 18.0003L34 30.5003L21.5 18.0003L18 21.5003L30.5 34.0003L18 46.5003L21.5 50.0003ZM34 67.3337C29.2778 67.3337 24.8889 66.4864 20.8334 64.792C16.7778 63.0975 13.25 60.7503 10.25 57.7503C7.25002 54.7503 4.9028 51.2225 3.20835 47.167C1.51391 43.1114 0.666687 38.7225 0.666687 34.0003C0.666687 29.3337 1.51391 24.9725 3.20835 20.917C4.9028 16.8614 7.25002 13.3337 10.25 10.3337C13.25 7.33366 16.7778 4.97255 20.8334 3.25033C24.8889 1.5281 29.2778 0.666992 34 0.666992C38.6667 0.666992 43.0278 1.5281 47.0834 3.25033C51.1389 4.97255 54.6667 7.33366 57.6667 10.3337C60.6667 13.3337 63.0278 16.8614 64.75 20.917C66.4722 24.9725 67.3334 29.3337 67.3334 34.0003C67.3334 38.7225 66.4722 43.1114 64.75 47.167C63.0278 51.2225 60.6667 54.7503 57.6667 57.7503C54.6667 60.7503 51.1389 63.0975 47.0834 64.792C43.0278 66.4864 38.6667 67.3337 34 67.3337ZM34 62.3337C41.7778 62.3337 48.4445 59.5559 54 54.0003C59.5556 48.4448 62.3334 41.7781 62.3334 34.0003C62.3334 26.2225 59.5556 19.5559 54 14.0003C48.4445 8.44477 41.7778 5.66699 34 5.66699C26.2222 5.66699 19.5556 8.44477 14 14.0003C8.44446 19.5559 5.66669 26.2225 5.66669 34.0003C5.66669 41.7781 8.44446 48.4448 14 54.0003C19.5556 59.5559 26.2222 62.3337 34 62.3337Z" />
  </svg>
);

export default IconCloseCircleOutline;
