import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconAirswap: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
  <svg fill="none" viewBox="0 0 40 40" className={className}>
    <path d="M29.1306 28.9805L20.0115 38.0754L15.4785 33.5544C20.3656 33.2719 25.0757 31.7001 29.1306 28.9805Z" />
    {/* eslint-disable max-len */}
    <path d="M37.7705 19.6388L36.655 18.5262L20.0104 1.92578L3.3659 18.5262L1.93164 19.9567C2.10871 20.098 2.26807 20.2569 2.44514 20.3982C3.02947 20.8927 3.63151 21.3518 4.25125 21.7757C10.8382 26.4026 19.0011 27.7977 26.5443 25.9611C29.6253 25.2194 32.547 23.9302 35.1853 22.1819C35.8405 21.7404 36.4779 21.2812 37.0976 20.7867C37.4341 20.5218 37.7705 20.2392 38.1069 19.939L37.7705 19.6388ZM24.4018 23.8066C19.4438 20.7514 13.7245 19.162 7.88118 19.1796C15.4775 15.8949 24.7913 15.4357 33.3438 20.3452C30.6169 22.0759 27.5713 23.2591 24.4018 23.8066Z" />
  </svg>
);

export default IconAirswap;
