import React, { FC, ReactElement } from 'react';

import { SvgIconProps } from '../Icon';

const IconCampaign: FC<SvgIconProps> = ({ className = '' }): ReactElement => (
  <svg viewBox="0 0 25 24" className={className}>
    <path d="M18.9316 11C18.9316 11.67 18.9316 12.33 18.9316 13C20.1316 13 21.6916 13 22.9316 13C22.9316 12.33 22.9316 11.67 22.9316 11C21.6916 11 20.1316 11 18.9316 11Z" />
    <path d="M16.9316 17.61C17.8916 18.32 19.1416 19.26 20.1316 20C20.5316 19.47 20.9316 18.93 21.3316 18.4C20.3416 17.66 19.0916 16.72 18.1316 16C17.7316 16.54 17.3316 17.08 16.9316 17.61Z" />
    <path d="M21.3316 5.6C20.9316 5.07 20.5316 4.53 20.1316 4C19.1416 4.74 17.8916 5.68 16.9316 6.4C17.3316 6.93 17.7316 7.47 18.1316 8C19.0916 7.28 20.3416 6.35 21.3316 5.6Z" />
    {/* eslint-disable-next-line max-len */}
    <path d="M4.93164 9C3.83164 9 2.93164 9.9 2.93164 11V13C2.93164 14.1 3.83164 15 4.93164 15H5.93164V19H7.93164V15H8.93164L13.9316 18V6L8.93164 9H4.93164ZM9.96164 10.71L11.9316 9.53V14.47L9.96164 13.29L9.48164 13H8.93164H4.93164V11H8.93164H9.48164L9.96164 10.71Z" />
    <path d="M16.4316 12.0004C16.4316 10.6704 15.8516 9.47039 14.9316 8.65039V15.3404C15.8516 14.5304 16.4316 13.3304 16.4316 12.0004Z" />
  </svg>
);

export default IconCampaign;
